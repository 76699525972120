$(document).ready(function () {

    function animateReduce() {
        var controller = new ScrollMagic.Controller();


        // var scene1 = new ScrollMagic.Scene({
        //     triggerElement: '.bg-text',
        //     triggerHook: '0.2',
        // }).setTween(TweenMax.to(".header__btn", 0.1, { className: "+=is-dark" })).addTo(controller);
        // END PIN

        var tl1 = new TimelineMax();
        tl1.from('.reduce__img-wrap', 0.7, {
            opacity: 0,
            ease: Power0.easeNone
        });
        var scene2 = new ScrollMagic.Scene({
            triggerElement: '.js-reduce',
            triggerHook: '0.6',
        }).setTween(tl1).addTo(controller);

        var tl = new TimelineMax();
        tl
            .from('.reduce__action-title--an', 0.9, {
                y: '100%',
                opacity: 0,
                ease: Power0.easeNone
            })
            .fromTo('.reduce__action-line span', 0.6, {
                width: '0%',
                ease: Power0.easeNone
            }, {
                    width: '100%',
                    autoAlpha: 1,
                    ease: Power0.easeNone
                })
            .to('.reduce__img--1', 0.1, {
                opacity: 0,
                ease: Power0.easeNone
            })
            .fromTo('.reduce__action-btn', 0.7, {
                opacity: 0,
                rotation: 90,
                ease: Power0.easeNone
            }, {
                    opacity: 1,
                    rotation: 0,
                    ease: Power0.easeNone
                })
            .fromTo('.reduce__action-btn-text', 0.5, {
                opacity: 0,
                scaleX: 0.8, scaleY: 0.8,
            },
                {
                    opacity: 1,
                    scaleX: 1, scaleY: 1,
                })
            // .fromTo('.reduce__action-btn-text', 0.5, {
            //     opacity: 1,
            //     // scaleX: 0.8, scaleY: 0.8,
            // },
            //     {
            //         opacity: 0,
            //         // scaleX: 1, scaleY: 1,
            //     })
            .fromTo('.reduce__action-btn', 1.5, {
                // opacity: 0,
                rotation: 90,
                ease: Power0.easeNone
            }, {
                    opacity: 1,
                    rotation: 0,
                    ease: Power0.easeNone
                })
            // .fromTo('.reduce__action-btn-text', 0.5, {
            //      opacity: 1,
            //     scaleX: 0.8, scaleY: 0.8,
            // },
            //     {
            //         opacity: 1,
            //         scaleX: 1, scaleY: 1,
            //     }, '-=0.5')
            .to('.reduce__img--1', 0.8, {
                opacity: 1,
                ease: Power0.easeNone
            })
            .to('.reduce__img--1', 1.5, {
                opacity: 0,
                ease: Power0.easeNone
            } )
            .to('.reduce__img--1', 1.5, {
                opacity: 0,
                ease: Power0.easeNone
            });

        // PIN
        var scene = new ScrollMagic.Scene({
            triggerElement: '.js-pin',
            triggerHook: '0',
            offset: 0,
            duration: '180%'
        }).setTween(tl).setPin(".js-pin", { pushFollowers: true }).addTo(controller);
    };




    function animateBenefit() {
        var controller = new ScrollMagic.Controller();

        var tl = new TimelineMax();

        tl
            .to(".benefit__img--1", 1, {
                opacity: 0,
            })
            .to(".benefit__img--2", 1, {
                opacity: 1
            }, '-=1')
            .to(".benefit__point--1 .point", 0.5, {
                opacity: 1
            })
            .to(".benefit__point--3 .point", 0.5, {
                opacity: 1
            }, '-=0.5')
            .to(".benefit__point--2 .point", 0.5, {
                opacity: 1
            }, '-=0.5')
            .to(".benefit__point--1 .line span", 0.5, {
                height: '100%'
            })
            .to(".benefit__point--2 .line span", 0.5, {
                height: '100%'
            }, '-=0.5')
            .to(".benefit__point--3 .line span", 0.5, {
                height: '100%'
            }, '-=0.5')
            .to(".benefit__content-left", 0.5, {
                y: 0,
                height: '100%',
                opacity: 1,
                overflow: 'visible'
            })
            .to(".benefit__content-right", 0.5, {
                y: 0,
                height: '100%',
                opacity: 1,
                overflow: 'visible'
            }, '-=0.5');
        var scene = new ScrollMagic.Scene({
            triggerElement: '.js-benefit-pin',
            triggerHook: '0',
            duration: '130%'
            // duration: '160%'
        }).setTween(tl).setPin(".js-benefit-pin").addTo(controller);
    };


    $('.feeling__main-desktop .feeling__link-title').click(function (e) {
        e.preventDefault();
        var tl = new TimelineMax();
        var link = $(this).closest('.feeling__link');
        var content = $(link).find('.feeling__link-content');
        var links = $(link).siblings('.feeling__link');
        var index = $(link).index();
        var img = $('.feeling__img').find('img').eq(index);
        tl
            .to(links, 0.8, {
                autoAlpha: 0,
                display: 'none'
            })
            .to(link, 0.5, {
                y: '0%',
                autoAlpha: 1,
            })
            .to(content, 0.8, {
                autoAlpha: 1,
                display: 'block'
            })
            .to($('.feeling__img'), 0.8, {
                opacity: 1
            }, '-=1')
            .to($('.feeling__img img'), 0.8, {
                autoAlpha: 0
            }, '-=1.2')
            .to(img, 0.8, {
                autoAlpha: 1
            }, '-=1.2')
        // .set($(".feeling__img"), {css:{background:'url(/images/feeling-4.png) center center no-repeat'}})
        // $('.feeling__main').hide();
        // var feelingBlock = $(this).attr('data-block');
        // $('.feeling__tab[data-block=' + feelingBlock + ']').addClass('is-visible');
    });

    $('.feeling__main-desktop .feeling__tab-link').click(function (e) {
        e.preventDefault();
        var tl = new TimelineMax();
        var content = $(this).closest('.feeling__link-content');
        var link = $(this).closest('.feeling__link');
        var links = $(link).siblings('.feeling__link');
        var position = ($(link).index() * 100) + '%';
        var img = $('.feeling__img img')[0];
        
        tl
            .to(content, 1, {
                autoAlpha: 0,
                display: 'none'
            })
            .to(link, 0.5, {
                autoAlpha: 1,
                y: position
            })
            .to(links, 1, {
                autoAlpha: 1,
                display: 'block'
            })
            .to($('.feeling__img'), 0.8, {
                autoAlpha: 0.4
            }, '-=1')
            .to($('.feeling__img img'), 0.8, {
                autoAlpha: 0
            }, '-=1')
            .to($(img), 0.8, {
                autoAlpha: 1
            }, '-=1')
        // .set($(".feeling__img"), {css:{background:'url(/images/feeling-1-m.jpg) center center no-repeat'}}) 
    });

    // $('.feeling__tab-link').click(function (e) {
    //     e.preventDefault();
    //     $('.feeling__tab').removeClass('is-visible');
    //     $('.feeling__main').fadeIn();
    // });

    $('.js-popup-open').click(function (e) {
        e.preventDefault();
        var popup = $(this).attr('data-popup');

        $('.popup[data-popup=' + popup + ']').addClass('is-visible');
        var t = $('.popup[data-popup=' + popup + ']').find('.popup-inner');
        var controller = new ScrollMagic.Controller();
        var scene3 = new ScrollMagic.Scene({
            triggerElement: t,
            triggerHook: '0.3',
            offset: 0,
            duration: '100%'
        }).setPin(t).addTo(controller);
        // END PIN

     
    });
    $('.ingr__list-item').click(function (e) {
        e.preventDefault();
        var w = $(window).outerWidth();
        var popup = $(this).attr('data-popup');

        if (w > 565) {
            $('.ingr__info[data-popup=' + popup + ']').fadeIn(600);
            $('.ingr__list').hide();
            $('.ingr__btn').hide();
        } else {
            $('.popup[data-popup=' + popup + ']').addClass('is-visible');
        }
    });
    $('.ingr__btn').click(function (e) {
        e.preventDefault();
        var w = $(window).outerWidth();

        if (w > 565) {
            $('.ingr__list').fadeIn();
        } else {
            $('.ingr__btn').hide();
            $('.ingr__list').fadeIn();
        //     // $.scrollTo($('.inside__title'), 1400)
        }
    });
    $('.ingr__list-close').click(function (e) {
        e.preventDefault();
        var w = $(window).outerWidth();
        
        if (w > 565) {
            $('.ingr__list').fadeOut();
        }else{
            $('.ingr__list').hide();
            $('.ingr__btn').fadeIn();
        }
    });

    $('.ingr__info-close').click(function (e) {
        e.preventDefault();

        $('.ingr__info').hide();
        $('.ingr__list').fadeIn()
        $('.ingr__btn').fadeIn()
    });

    $('.js-popup-close').click(function (e) {
        e.preventDefault();
        if (e.target.className.indexOf('js-popup-close') !== -1) {
            $(this).removeClass('is-visible');
        }
    });

    $('.hero__slide').click(function (e) {
        // $('.hero__slide-circle').addClass('is-active js-popup-open');
        if ($('.hero__slide-circle').hasClass('is-active')) {
        } else {
            $('.hero').addClass('is-active');
            $('.hero__slide-circle').addClass('is-active');
            $('.popup-contact').addClass('is-visible');
        }

    });

    $('.popup-contact-close').click(function () {

        $('.popup-contact').removeClass('is-visible');
        $('.hero').removeClass('is-active');
        $('.hero__slide-circle').removeClass('is-active');
    })


    $('.js-info-next').click(function () {


        var active = $(this).closest('.ingr__info').index();
       
        // console.log( $(active).next('.ingr__info').index());
        // console.log(active.index() == ($('.ingr__info').length -1));
        if (active < ($('.ingr__info').length - 1)) {
            $('.ingr__info').eq(active + 1).fadeIn(600);
            $('.ingr__info').eq(active).hide();
        } else {
            active = 0;
            $('.ingr__info').eq(active).fadeIn(600);
            $('.ingr__info').eq($('.ingr__info').length - 1).hide();
        }

    })

    $('.js-review-slider').bxSlider({
        nextText: '',
        prevText: '',
        speed: 500

    });

    function reviewFiltered() {
        var perLoad = 4;
        var rating;
        var filtered;
        var filteredLength;
        var loadMore = $('.js-review-load');

        $('.js-filter-rating').click(function () {
            rating = $(this).attr("data-rating");
            loadMore.addClass('filter')
                .attr('data-rating', rating)
                .show();

            $(".reviews__item").addClass('js-review-hidden').hide();
            filtered = $(".reviews__item").filter(function (elem) {
                return $(this).attr("data-rating") == rating;
            });
            filteredLength = filtered.length;
            $(filtered).slice(0, perLoad).each(function () {
                $(this).slideDown(500);
                $(this).removeClass('js-review-hidden');
            })
            if (filteredLength <= perLoad) {
                loadMore.hide();
            }
        });

        loadMore.click(function (e) {
            e.preventDefault();

            if (!($(this).hasClass("filter"))) {
                $('.js-review-hidden').slice(0, perLoad).each(function () {
                    $(this).slideDown(500);
                    $(this).removeClass('js-review-hidden');
                })
                filteredLength = $('.js-review-hidden').length;
                if (filteredLength == 0) {
                    loadMore.hide();
                }
            } else {
                filtered = filtered.filter(".js-review-hidden");
                filteredLength = filtered.length;
                filtered.slice(0, perLoad).each(function () {
                    $(this).slideDown(500);
                    $(this).removeClass('js-review-hidden');
                })
                if (filteredLength <= perLoad) {
                    loadMore.hide();
                }
            }

        });
    }

    $(document).on('click', '.js-scroll-to', function () {

        var scroll = $(this).attr('data-scroll');
        var speed = parseInt($(this).attr('data-speed'), 10);
        //		var offset = parseInt($(this).attr('data-offset'), 10);

        if (isNaN(speed)) {
            speed = +900;
        }
        $.scrollTo('#js-' + scroll + '', speed);
        return false;

    });
    function startSpecialOffer() {
        var minutes = $(".minutes");
        var seconds = $(".seconds");
        var totalSeconds = 300;
        setInterval(setTime, 1000);

        function setTime() {
            if (totalSeconds > 0) {
                --totalSeconds;
                
                $(seconds).text(pad(totalSeconds % 60));
                $(minutes).text(pad(parseInt(totalSeconds / 60)));
            }

        }

        function pad(val) {
            var valString = val + "";
            if (valString.length < 2) {
                return "0" + valString;
            } else {
                return valString;
            }
        }
    }
    $('.js-submit-form').click(function (e) {
        e.preventDefault();
        startSpecialOffer();
        var w = $(window).outerWidth();
        $('.popup-contact').removeClass('is-visible');
        $('.header').addClass('special-offer');
        $('.order__cards').addClass('special-offer');
        $('.order__btn').attr('href', $('.order__btn').data('link-special'));
        if(w< 550){
              $('.header__btn').text('Click here')
        }else{
            $('.header__btn').text('See special price')
        }
      
        setTimeout(function () {
            $('.header').removeClass('special-offer');
            $('.order__cards').removeClass('special-offer');
            $('.header__btn').text('Place an order')
        $('.order__btn').attr('href', $('.order__btn').data('link-orig'))
        }, 300000) 
    });
    animateReduce();
    animateBenefit();
    reviewFiltered();
    // startSpecialOffer();
});

 $('.js-scroll').click(function () {

    $('.popup').removeClass('is-visible');
    var speed = parseInt($(this).attr('data-speed'), 10); 
setTimeout(function(){

    if (isNaN(speed)) {
        speed = +1000;
    }
    $.scrollTo('#js-order', speed);
}, 600)
    return false;

});
 $('.time').click(function () {
var w = $(window).outerWidth();
if(w<550){

    var speed = parseInt($(this).attr('data-speed'), 10); 
setTimeout(function(){

    if (isNaN(speed)) {
        speed = +1000;
    }
    $.scrollTo('#js-order', speed);
}, 600)
    return false;
}
     

});

document.querySelector('.hero__slide').addEventListener('touchstart', handleTouchStart, false);        
document.querySelector('.hero__slide').addEventListener('touchmove', handleTouchMove, false);
var xDown = null;                                                        
var yDown = null;  

function handleTouchStart(evt) {                                         
    xDown = evt.touches[0].clientX;                                      
    yDown = evt.touches[0].clientY;                                      
}; 

function handleTouchMove(evt) {
    if ( ! xDown || ! yDown ) {
        return;
    }
    var xUp = evt.touches[0].clientX;                                    
    var yUp = evt.touches[0].clientY;
    var xDiff = xDown - xUp;
    var yDiff = yDown - yUp;

    if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
        if ( xDiff > 0 ) {
        /* left swipe */ 
        } else {
            if ($('.hero__slide-circle').hasClass('is-active')) {
            } else {
                $('.hero').addClass('is-active');
                $('.hero__slide-circle').addClass('is-active');
                $('.popup-contact').addClass('is-visible');
            }
        }                       
    } else {
        if ( yDiff > 0 ) {
        /* up swipe */ 
        } else { 
            if ($('.hero__slide-circle').hasClass('is-active')) {
            } else {
                $('.hero').addClass('is-active');
                $('.hero__slide-circle').addClass('is-active');
                $('.popup-contact').addClass('is-visible');
            }
        }                                                                 
    }
    /* reset values */
    xDown = null;
    yDown = null;                                             
};